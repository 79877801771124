<template>
	<w-layout fill-height column>
		<w-flex v-if="quickfolders.length == 0" v-t="$t('quickfolders.no_quick_folders')" shrink headline text-xs-center />
		<w-layout v-else fill-height align-center justify-center column wrap>
			<w-flex v-if="documentRights" align-center grow justify-center pb-3>
				<w-layout column>
					<w-flex shrink>
						<w-layout row justify-center>
							<w-flex v-t="'quickfolders.selection_title'" title text-xs-center my-3></w-flex>
						</w-layout>
					</w-flex>
					<w-flex grow>
						<w-layout row wrap justify-center align-center grid-list-sm fluid>
							<w-flex v-for="(quickfolderElt, index) in quickfoldersComputed" :key="index" shrink my-1 mx-2>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-card height="8em" hover max-width="90vw" width="20em" tile v-on="on" @click="openQuickFolder(quickfolderElt)">
											<v-card-text class="fill-height">
												<w-layout fill-height align-center justify-center column>
													<w-flex shrink headline ma-0 text-xs-center>{{ quickfolderElt.name }}</w-flex>
												</w-layout>
											</v-card-text>
										</v-card>
									</template>
									<span v-text="quickfolderElt.folder.location + quickfolderElt.folder.name" />
								</v-tooltip>
							</w-flex>
							<w-flex shrink my-1 mx-2>
								<QuickFoldersOtherFolders :callback="callback" :selected-vendor-id="realCompanyId" :origin="origin" />
							</w-flex>
						</w-layout>
					</w-flex>
				</w-layout>
			</w-flex>
		</w-layout>
		<QuickFoldersUploader
			v-if="dialogUpload"
			:value="quickfolder"
			:selected-month-folder="getMonthFolder"
			:contains-months="getMonthFolder.id != null"
			:callback="callback"
			:origin="origin"
			@close="dialogUpload = false"
		/>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'QuickFoldersList',
	components: {
		QuickFoldersOtherFolders: () => ({
			component: import('@/components/QuickFolders/OtherFolders')
		}),
		QuickFoldersUploader: () => ({
			component: import('@/components/QuickFolders/QuickFoldersUploader')
		})
	},
	props: {
		callback: {
			required: false,
			type: Function,
			default: null
		},
		documentRights: {
			required: true,
			type: Boolean
		},
		quickfolders: {
			required: true,
			type: Array
		},
		selectedVendorId: {
			required: false,
			type: Number,
			default: null
		},
		origin: {
			required: false,
			type: String,
			default: null
		}
	},
	data: () => ({
		dialogUpload: false,
		loading: true,
		quickfolder: {}
	}),
	computed: {
		...mapState({
			company: state => state.company.selected
		}),
		realCompanyId: function () {
			if (this.vendorId) {
				return this.vendorId
			}
			if (this.selectedVendorId) {
				return this.selectedVendorId
			}
			return this.company.id
		},
		getMonthFolder: function () {
			return this.findMonthFolder(this.quickfolder)
		},
		quickfoldersComputed: function () {
			return this.quickfolders.map(item => {
				const monthFolder = this.findMonthFolder(item)
				if (monthFolder && monthFolder.id) {
					item.path = monthFolder.path + monthFolder.name + '/'
				}
				return item
			})
		}
	},
	methods: {
		findMonthFolder: function (quickfolder) {
			let result = {}
			if (quickfolder && quickfolder.children && quickfolder.children.length > 0) {
				let currentDate = new Date()
				let found = quickfolder.children.find(
					f => f.time_interval && new Date(f.time_interval.start) <= currentDate && new Date(f.time_interval.end) >= currentDate
				)
				if (found && found.id) {
					result = found
				}
			}
			return result
		},
		openQuickFolder: function (quickfolder) {
			this.quickfolder = quickfolder
			this.dialogUpload = true
		},
		selectQuickFolder: function (quickfolder) {
			this.quickfolder = quickfolder
			this.dialogUpload = true
		}
	}
}
</script>
